// Generated by Framer (b75150a)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["f8HjnNQRp"];

const serializationHash = "framer-V1atU"

const variantClassNames = {f8HjnNQRp: "framer-v-wqmvu6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "f8HjnNQRp", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "Logo", fit: "fit", pixelHeight: 42, pixelWidth: 41, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/TRpBDWrx7vkdTPCWuXLADzHIw2U.svg"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-wqmvu6", className, classNames)} data-framer-name={"Hope"} layoutDependency={layoutDependency} layoutId={"f8HjnNQRp"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-V1atU.framer-hkvqnt, .framer-V1atU .framer-hkvqnt { display: block; }", ".framer-V1atU.framer-wqmvu6 { height: 48px; position: relative; width: 48px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKVe7Tdyrg: React.ComponentType<Props> = withCSS(Component, css, "framer-V1atU") as typeof Component;
export default FramerKVe7Tdyrg;

FramerKVe7Tdyrg.displayName = "Hope";

FramerKVe7Tdyrg.defaultProps = {height: 48, width: 48};

addFonts(FramerKVe7Tdyrg, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})